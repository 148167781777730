<template>
<div class="editcss-area">

    <link rel="stylesheet" :ref="editcss" :href="cssURL" />
    
    <h5 >編集 
      <b v-on:click="closeEditor" style=" cursor: pointer; float:right;">X</b>
    </h5> 
   
       <MonacoEditor 
        :key="editorKey"
        v-model="data"
        style="width:100%; height:75%;"
        class="editor" 
        ref="editor"
        :options="option"
        :language="ext" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
       <button style="margin:1%; float:right;" class="btn btn-success" v-on:click="save"> 保存 </button>
  
  
</div>

</template>

<script >
import { defineComponent } from "vue";
import MonacoEditor from 'monaco-editor-vue3'

export default defineComponent({
  name: "Editor",
  components:  { MonacoEditor } ,
  props: {  params: Object  } ,
    data() { return {
      client :undefined,
        editorKey: 0,
        option : {"value":this.data },
        ext : "txt",
        data : "",
        editingPath : "",
        pathOptions : [ /* { text: 'ダッシュボード全体', value: '*' } */ ]
       }
    },
    
    async beforeMount  (){  
           
      this.$emit('show-loader',true);
      try {
      this.client = await window.WebDavClient.createCustomClient(
        process.env.VUE_APP_APP_API_V4_URL + "file/access/"
      );
    } catch (e) {
      console.error("Error initializing WebDavClient:", e);
    }

          this.data = await this.arrayBufferToText(await this.client.getFileContents(this.params.path))
          this.editingPath =  this.params.path;
          this.ext = this.params.ext;
          this.editorDidChange(this.data);
          this.editorKey += 1; 
                
          this.$emit('show-loader',false);
        },
    
      methods: {

      
      async textToArrayBuffer(text , encoding = "utf-8") {
    
        const encoder = new TextEncoder(encoding);
        return encoder.encode(text).buffer; // テキストを ArrayBuffer に変換
      },
      async arrayBufferToText(buffer , encoding = "utf-8") {
    
        const decoder = new TextDecoder(encoding);
        return decoder.decode(buffer); // ArrayBuffer をテキストに変換
      },
      editorDidChange(text){     
          
          this.data  = text; 
          this.option.value = text;
        }, 
        closeEditor(){
          
          this.$emit('close-editor');
        },
        async save (){
          try{
          
            if ( await this.client.putFileContents( this.params.path ,  await this.textToArrayBuffer(this.data), { overwrite: false })){
            
              this.$emit( 'show-flash', {"message":"保存しました。","type": "success"});
            } else {

              this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
            }

          } catch(e){

            this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
            console.error(e);
          }
        }
    }
    
    });
</script>
<style>

.editcss-area {
    background: lightgray;
    position: fixed;

    width: 100%;
    padding  : 1%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 10%;
    top: 0px;
    left: 0px;
    height: 100%;
    display: inline-block;
    width: 100%;
    position: fixed;

}
</style>