
import { defineComponent } from "vue";
import Editor from "@/components/edit/Editor.vue";
import EMailEditor from "@/components/edit/EMailEditor.vue";

export default defineComponent({
  name: "Directory",
  data() {
    return {
      newDir :"",
      isDragOver : false , 
      uploadFileName : "ファイルのアップロード",
      editable: false,
      uploadable :false,
      deletable: false,
      filePath : "",
      fileData : undefined,
      editableFiles : ["csv", "eml", "xml","txt"],
      client: undefined as any,
      ds: [] as Array<{ name: string; isDirectory: boolean }>, // ディレクトリとファイルのリスト
      currentPath: "/", // 現在のディレクトリパス
      breadcrumb: ["Root"], // パスの履歴を管理
      showPopup: false, // ポップアップの表示状態
      showEditorPopup: false, // エディターポップアップの表示状態
      selectedFile: {name: "",type:""} as { name: string; type: string } | null, // 選択されたファイル情報
    };
  },
  async mounted(this:any) {
    this.$emit("show-header");
    this.$emit("show-loader", true);

    if (this.$route.query.editable !== undefined) {
      this.editable = true; // editが存在すればtrue
    }
    if (this.$route.query.uploadable !== undefined) {
      this.uploadable = true; // editが存在すればtrue
    }
    if (this.$route.query.deletable !== undefined) {
      this.deletable = true; // editが存在すればtrue
    }
    
   //  this.css = `data:text/css;base64,${Buffer.from( "" , 'utf-8').toString('base64')}`;
   //     this.$emit('show-edit-css',{ show:true , path : this.$route.path });

    try {
      this.client = await (window as any).WebDavClient.createCustomClient(
        process.env.VUE_APP_APP_API_V4_URL + "file/access/"
      );
     
      await this.getDirectory(this.currentPath);
    } catch (e) {
      console.error("Error initializing WebDavClient:", e);
    }

    this.$emit("show-loader", false);
  },
  computed: {
    filesAndFolders(this:any) {
      return  this.ds.map((item:any) => ({
        name: item.name,
        type: item.isDirectory ? "folder" : "file",
      }));
    },
    lookAtFileExtension(this: any){

      return this.selectedFile.name.split(".").pop()?.toLowerCase();
    },
    isEditableFile(this: any): boolean {
      // ファイルが選択されており、拡張子が csv, eml, xml の場合に true
      if (!this.selectedFile) return false;
      const allowedExtensions = this.editableFiles ;
      const fileExtension = this.selectedFile.name.split(".").pop()?.toLowerCase();
      return allowedExtensions.includes(fileExtension || "");
    }
  },
  methods: {
    async createDir (this:any){

      if(this.newDir !== ""){
        try{

        
const newPath = this.currentPath + "/"+ this.newDir;
          
         for (let i=0 ; i < this.ds.length ;i++ ){

            if ( this.newDir ==  this.ds[i].name){
              
              this.$emit('show-flash',{ "message": "ディレクトリが存在します。","type": "warning"});
              return;
            }
         }

         await this.client.createDirectory(  newPath );
      
          
        this.$emit('show-flash',{ "message": "ディレクトリを作成しました。","type": "success"});
        await this.getDirectory(this.currentPath);
       }catch (e){

        console.error(e);
        this.$emit('show-flash',{ "message": "ディレクトリの作成に失敗しました。","type": "warning"});  

       }

      }else {
        this.$emit('show-flash',{ "message": "ファイル名を設定してください。","type": "warning"});  
              
      }
    },
    onDrop(e:any){

            this.changeUploadinput(e);
        },
         onDrag (state:any){
             this.isDragOver = state;
        },
        async  changeUploadinput(this:any , e:any){
           //  const file = e.dataTransfer.files[0] ;

            const inputElement = e.dataTransfer || e.target || e.srcElement ; // input 要素を取得
            const file:any = inputElement.files[0]; 
      
            // this.uploadFileName = file.name;
             //try{
             if ( 800000000 < file.size ){
                
                
                this.emit('show-flash',{ "message": "ファイルのサイズは400MB以下でお願いい致します。","type": "warning"});  
                   
                return ;
             }
             //}
          
             const reader = new FileReader();

// ファイルの内容が読み込まれたときの処理
reader.onload = async (event:any) => {
    const arrayBuffer = event.target.result; // ArrayBuffer を取得
    console.log("ArrayBuffer:", arrayBuffer);

    try{
          const filePath =`${this.currentPath}${file.name}`;
          if ( await this.client.putFileContents( filePath ,  arrayBuffer )){
          
            this.$emit( 'show-flash', {"message":"保存しました。","type": "success"});
            await this.getDirectory(this.currentPath);
          } else {

            this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
          }

        } catch(e){

          this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
          console.error(e);
        }

    // 必要に応じて ArrayBuffer を処理
};

// エラー発生時の処理
reader.onerror = (error) => {
    console.error("ファイル読み取りエラー:", error);
};

// ファイルを ArrayBuffer として読み込む
reader.readAsArrayBuffer(file);
           
             /*
            return;
              this.$emit('show-flash', true);
            if ( await this.client.putFileContents( this.params.path + "/"+this.filename , 
             await this.textToArrayBuffer(this.data), { overwrite: false })
            ){
           
                   
                   this.$emit('show-flash',{ "message": "アップロードしました。","type": "success"}); 
                   this.uploadFileName = "ファイルのアップロード" ; 
                   window.location.reload();
            } else {
                  this.showFolder();
                   this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
            }
            
            }catch (e){
                
                this.$emit('show-flash',{ "message": "ファイルのアップロードに失敗しました。","type": "warning"});  
               console.log(e) ; 
            }
                         this.$emit('show-flash', false);
                         */
         },
    
    showLoader (param:any ){
    
      this.$emit('show-loader', param);    
    },
  
    showFlash (param:any ){
    
      this.$emit('show-flash', param);    
    },
  
    closeEditor(this:any){
      
      if(window.confirm("編集を終了しますか？\n*保存していない編集内容がある場合は破棄されます。")){
        this.showEditorPopup=false;
      }
    },
    async getDirectory(this: any,  path: string) {
      try {
       this.ds = [];
        const directoryItems = await this.client.getDirectoryContents(path);

        this.ds = directoryItems
          .filter((d: any) => !d.basename.startsWith("."))
          .map((d: any) => ({
            name: d.basename,
            isDirectory: d.type === "directory",
          }));

        this.currentPath = path;
        this.updateBreadcrumb(path);
      } catch (e) {
        console.error("Error fetching directory contents:", e);
      }
    },
    async onSelectFile(this:any , item: { name: string; type: string }) {
      if (item.type === "folder") {
        const newPath = `${this.currentPath}${item.name}/`;
        this.$emit("show-loader", true);
        await this.getDirectory(newPath);
        this.$emit("show-loader", false);
      } else {
        this.selectedFile = item;
        this.showPopup = true;
      }
    },
    closePopup(this:any) {
      this.showPopup = false;
      this.selectedFile = null;
    },
    closeEditorPopup(this: any) {
      this.showEditorPopup = false;
    },
    navigateToBreadcrumb(this:any , index: number) {

      
      const breadcrumb = this.currentPath.split('/');
      if (index < breadcrumb.length - 1) {

        var path ="";
        for ( let i=0; i<index+1;i++ ){
          
          path += "/"+breadcrumb[i];
        }

        this.$emit("show-loader", true);
        this.getDirectory(path).finally(() => {
          this.currentPath=path;
          this.$emit("show-loader", false);
        });
      }
    },
    updateBreadcrumb(this:any, path: string) {
      const parts = path.split("/").filter((p) => p); // 空文字列を除去
      this.breadcrumb = ["Root", ...parts];
    },
   async deleteDir(this:any){
    if(this.ds.length){
      this.$emit( 'show-flash', {"message":"ファイルが存在する場合は削除できません。","type": "warning"});
      return;
    }
    this.selectedFile={};
    this.selectedFile.name="";
    await this.deleteFile();
   },
    async deleteFile(this:any){
    
     if (this.selectedFile.name == "" && this.currentPath=="/" ){
      
      this.$emit( 'show-flash', {"message":"ルートフォルダは削除できません。","type": "warning"});
        

     }

      if( !(window as any ). confirm("本当に削除しますか？")){

        return ;
      }
      this.filePath =`${this.currentPath}${this.selectedFile.name}`;
      
      const name =(window as any ). prompt("確認の為、削除するファイルパス/ファイル名を入力してください。\n"+this.filePath );
      
      if( this.filePath !== name ){
        this.$emit( 'show-flash', {"message":"ファイル名が違います。","type": "danger"});
        return;
      }
      
      try{
          
          console.log(await this.client.deleteFile( this.filePath ))
          
            this.$emit( 'show-flash', {"message":"削除しました。","type": "success"});
         
      } catch(e:any){

          this.$emit( 'show-flash', {"message":"削除に失敗しました。","type": "danger"});
          console.error(e);
      }
      
      if (this.selectedFile.name ==""){
        let p=this.currentPath.split('/');
        p.pop();p.pop();
       
        p=p.join('/');
        this.currentPath=p;
        this.getDirectory( this.currentPath );
      }else{
        await this.getDirectory(this.currentPath);
      }
      this.closePopup() ;

      
    },
    
    async onDownload (this :any){

      try{
      // this.selectedFile.name
      this.filePath =`${this.currentPath}${this.selectedFile.name}`;
      
      const arrayBuffer = await this.client.getFileContents(this.filePath);
      const extension = this.selectedFile.name.split('.').pop().toLowerCase();
      const mimeTypes:any = {
      'txt' :  'text/plain',
      'html':  'text/html',
      'css' :  'text/css',
      'js'  :  'application/javascript',
      'json':  'application/json',
      'xml' :  'application/xml',
      'png' :  'image/png',
      'jpg' :  'image/jpeg',
      'jpeg':  'image/jpeg',
      'gif' :  'image/gif',
      'pdf' :  'application/pdf',
      'csv' :  'text/csv',
      'zip' :  'application/zip',
      'rar' :  'application/vnd.rar',
      '7z'  :  'application/x-7z-compressed',
      'mp3' :  'audio/mpeg',
      'mp4' :  'video/mp4',
      'mov' :  'video/quicktime',
      'avi' :  'video/x-msvideo',
      'doc' :  'application/msword',
      'docx':  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'xls' :   'application/vnd.ms-excel',
      'xlsx':  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    // デフォルト MIME タイプを設定
    const mimeType = mimeTypes[extension] || 'application/octet-stream';

    // Blob を生成
    const blob = new Blob([arrayBuffer], { type: mimeType });

    // ダウンロードリンクを生成してクリック
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.selectedFile.name;

    // ダウンロード実行
    document.body.appendChild(link);
    link.click();

    // 後処理
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log(`File ${this.selectedFile.name} downloaded successfully.`);
    } catch (error:any) {
      
      console.error(`Failed to download file: ${error.message}`);
    }

    },
    async onEdit(this: any) {
      if (this.selectedFile) {
        // ファイルパスを変数に保存
        this.filePath = `${this.currentPath}${this.selectedFile.name}`;
        this.showPopup = false;
        this.showEditorPopup = true; // エディターポップアップを表示
      }
    },
  },components:  { Editor,EMailEditor }
});

