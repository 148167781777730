<template>
<div style="text-align: left" class="editcss-area">

    <link rel="stylesheet" :ref="editcss" :href="cssURL" />
    
    <h5 >
      <b v-on:click="closeEditor" style=" cursor: pointer; float:right;">X</b>
    </h5> 
   
    <p > 
      <label class="email-label" for='tags'>From :</label>
      <input ref="from" type ="text" autofocus/> 
    </p>
    
    <p >
    <label class="email-label" for='tags'>To :</label>  
    <input ref="to"  name='tags' autofocus /> <br>
    </p>

    <p >
    <label class="email-label" for='tags'>Cc :</label>  
    <input ref="cc"  name='tags' autofocus /> <br>
    </p>
    <p >
    <label class="email-label" for='tags'>Bcc :</label>  
    <input ref="bcc" type ="text" autofocus /> 
    </p>
    <p >
    <label class="email-label" >Subject :</label>  
    <input style="width: 40%;" ref="subject" v-model="subject" type ="text" autofocus /> 
    </p>
    
   
       <MonacoEditor 
        :key="editorKey"
        v-model="data"
        style="width:100%; height:40%;"
        class="editor" 
        ref="editor"
        :options="option"
        :language="ext" 
        @change="editorDidChange"
       >
       </MonacoEditor>
    
       <button style="margin:1%; float:right;" class="btn btn-success" v-on:click="save"> 保存 </button>
  
  
</div>

</template>

<script >
import { defineComponent } from "vue";
import MonacoEditor from 'monaco-editor-vue3'

import Tagify from '@yaireo/tagify'

import '@yaireo/tagify/dist/tagify.css';

export default defineComponent({
  name: "Editor",
  components:  { MonacoEditor } ,
  props: {  params: Object  } ,
    data() { return {
      client :undefined,
        editorKey: 0,
        option : {"value":this.data },
        ext : "txt",
        from : [],
        data : "",
        editingPath : "",
        pathOptions : [ /* { text: 'ダッシュボード全体', value: '*' } */ ],
        fromTag : undefined,
        bccTag : undefined,
        ccTag : undefined,
        toTag :undefined

       }
    },
     async beforeMount  (){       
      
      this.$emit('show-loader',true);
    
      try {
      this.client = await window.WebDavClient.createCustomClient(
        process.env.VUE_APP_APP_API_V4_URL + "file/access/"
      );
    } catch (e) {
      console.error("Error initializing WebDavClient:", e);
    }

       
    const data = this.parseEmail(await this.arrayBufferToText(await this.client.getFileContents(this.params.path)));
          
            this.fromTag = new Tagify( this.$refs.from , {
              maxTags: 1,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 
              dropdown: {
              enabled: 0
              }
            });

            this.toTag= new Tagify( this.$refs.to , {
              maxTags: 20,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 
              dropdown: {
              enabled: 0
              }
            });

            this.bccTag= new Tagify( this.$refs.bcc , {
              maxTags: 20,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 
              dropdown: {
              enabled: 0
              }
            });

            this. ccTag = new Tagify( this.$refs.cc , {
              maxTags: 20,
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, 
              dropdown: {
              enabled: 0
              }
            });

            
            this.fromTag.addTags(data.from.split(',') );
            
            this.toTag.addTags(data.to.split(',') );

            this.ccTag.addTags(data.cc.split(',') );

            this.bccTag.addTags(data.bcc.split(',') );

          
          this.subject = data.subject;

          this.editingPath =  this.params.path;
          this.ext = this.params.ext;
          this.editorDidChange(data.body);
          this.editorKey += 1;   
          this.$emit('show-loader',false);   

      },

      methods: {

      parseEmail (text) {
    const lines = text.trim().split("\n");
    let from = "", to = "", cc = "", bcc = "", subject = "", body = "";

    lines.forEach((line, index) => {
        if (line.startsWith("From:")) {
            from = line.replace("From:", "").trim().replace(/\s+/g, "");
        } else if (line.startsWith("To:")) {
            to = line.replace("To:", "").trim().replace(/\s+/g, "");
        } else if (line.startsWith("Cc:")) {
            cc = line.replace("Cc:", "").trim().replace(/\s+/g, "");
        } else if (line.startsWith("Bcc:")) {
            bcc = line.replace("Bcc:", "").trim().replace(/\s+/g, "");
        } else if (line.startsWith("Subject:")) {
            subject = line.replace("Subject:", "").trim();
        } else if (index > 5) { // 5行目以降はボディ部分
            body += line + "\n";
        }
    });

    return {
        from,
        to,
        cc,
        bcc,
        subject,
        body
    };
      },
      async textToArrayBuffer(text , encoding = "utf-8") {
    
        const encoder = new TextEncoder(encoding);
        return encoder.encode(text).buffer; // テキストを ArrayBuffer に変換
      },
      async arrayBufferToText(buffer , encoding = "utf-8") {
    
        const decoder = new TextDecoder(encoding);
        return decoder.decode(buffer); // ArrayBuffer をテキストに変換
      },
      editorDidChange(text){     
          
          this.data  = text; 
          this.option.value = text;
        }, 
        closeEditor(){
          
          this.$emit('close-editor');
        },
        async save (){

          var text= "From:";
          for (let i=0; i< this.fromTag.value.length; i++ )
          {

            text += " " + this.fromTag.value[i].value+",";
          }
          text=text.slice(0,-1);
          text += "\n";
          
          text+= "To:";
          for (let i=0; i< this.toTag.value.length; i++ )
          {

            text += " " + this.toTag.value[i].value+",";
          }
          text=text.slice(0,-1);
          text += "\n";
          
          text+= "Cc:";
          for (let i=0; i< this.ccTag.value.length; i++ )
          {

            text += " " + this.ccTag.value[i].value+",";
          }
          text=text.slice(0,-1);
          text += "\n";
          
          text+= "Bcc:";
          for (let i=0; i< this.bccTag.value.length; i++ )
          {

            text += " " + this.bccTag.value[i].value+",";
          }
          text=text.slice(0,-1);
          text += "\n";
          
          text += "Subject: " + this.$refs.subject.value + "\n";
        
          text += "\n";

          text +=  this.data;

          
          try{
          
            if ( await this.client.putFileContents( this.params.path ,  await this.textToArrayBuffer(text))){
            
              this.$emit( 'show-flash', {"message":"保存しました。","type": "success"});
            } else {

              this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
            }

          } catch(e){

            this.$emit( 'show-flash', {"message":"保存に失敗しました。","type": "danger"});
            console.error(e);
          }
            
        }
    }
    
    });
</script>
<style>
.email-label {

  width :80px;
  
}
.editcss-area {
    background: lightgray;
    position: fixed;

    width: 100%;
    padding  : 1%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 10%;
    top: 0px;
    left: 0px;
    height: 100%;
    display: inline-block;
    width: 100%;
    position: fixed;

}
</style>